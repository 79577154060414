import state from "./state";
import axios from "axios";
import { i18n } from "../../main";
import keyCloak from "../../main"
import Role from "@/helpers/role";

export default {
  namespaced: true,
  state: state,
  actions: {
    async selectLanguage(context, languageCode) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/user/language/${languageCode}`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setUser", response.data.data);
          await context.dispatch(
            "translations/loadTranslations",
            {},
            { root: true }
          );
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadUser(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/user`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setUser", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getUserRole(context) {
      let timeout = setTimeout(() => {
        context.commit("setSpinnerState", true, { root: true });
      }, 300);

      await axios
        .get(`/${process.env.VUE_APP_API_URI}/user`, {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        })
        .then((response) => {
          context.commit("setUser", response.data.data);
          context.commit("setSpinnerState", false, { root: true });
          clearTimeout(timeout);

          //If everything was ok and the user is avaiable, than we can load the masterdata stuff
          this.dispatch("waves/loadInterestedTrainingTypes", { root: true });
          this.dispatch("configurations/getScenariosAction", { root: false });
          this.dispatch("configurations/loadAllOnSiteContactWays", {
            root: false,
          });
          this.dispatch("configurations/loadAllOffSiteContactWays", {
            root: false,
          });
          this.dispatch("projects/loadProjects", { root: false });
          this.dispatch("translations/loadActiveLanguages", { root: false });
          this.dispatch("users/loadUserMarket");
          this.dispatch("markets/getMarkets", { root: false });
        })
        .catch((ex) => {
          context.commit("setUserRole", null);
          context.commit("setSpinnerState", false, { root: true });
          clearTimeout(timeout);

          var errorData = {
            show: true,
            title: i18n.global.t("USER_NOT_IMPORTED_HEADER"),
            text: i18n.global.t("USER_NOT_IMPORTED"),
            okText: i18n.global.t("LOGOUT"),
            onOk: () => {
              context.commit(
                "waves/setErrorModal",
                { show: false },
                { root: true }
              );
              keyCloak.logout();
            },
          };

          if (ex.response?.data === "User not found") {
            (errorData.title = i18n.global.t("USER_NOT_IMPORTED_HEADER")),
              (errorData.text = i18n.global.t("USER_NOT_IMPORTED")),
              (errorData.okText = i18n.global.t("LOGOUT")),
              (errorData.onOk = () => {
                context.commit(
                  "waves/setErrorModal",
                  { show: false },
                  { root: true }
                );
                keyCloak.logout();
              });
          }

          if (ex.response?.data === "No market assigned to user") {
            (errorData.title = i18n.global.t("USER_NO_ROLE_HEADER")),
              (errorData.text = i18n.global.t("USER_NO_ROLE")),
              (errorData.okText = i18n.global.t("LOGOUT")),
              (errorData.onOk = () => {
                context.commit(
                  "waves/setErrorModal",
                  { show: false },
                  { root: true }
                );
                keyCloak.logout();
              });
          }

          context.commit("waves/setErrorModal", errorData, { root: true });
        });
    },
    async loadUserMarket(context) {
      const response = await axios.get(
        `/${process.env.VUE_APP_API_URI}/user/market`,
        {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        }
      );
      context.commit("setUserMarket", response.data.data);
    },
    async setUserMarket(context, market) {
      await axios.put(`/${process.env.VUE_APP_API_URI}/user/market`, market, {
        crossdomain: true,
        headers: {
          CallSpinner: true,
        },
      });
      context.commit("setUserMarket", market);
      await context.dispatch(
        "waves/reloadWaveWaveConfiguration",
        {},
        { root: true }
      );
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getUserRole(state) {
      return state.user.role;
    },
    getUserMarket(state) {
      return state.user_market;
    },
    getUserMarketName(state) {
      return state.user_market?.name;
    },
    getUserMarketShort(state) {
      return state.user_market?.short;
    },
    isAdminUser(state) {
      return Role.ADMIN.includes(state.user.role);
    },
    isMarketUser(state) {
      return Role.MARKET.includes(state.user.role);
    },
    isAgencyUser(state) {
      return Role.LEADAGENCY.includes(state.user.role);
    },
    isMarketOrAgencyUser(state) {
      return (
        Role.MARKET.includes(state.user.role) ||
        Role.LEADAGENCY.includes(state.user.role)
      );
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserMarket(state, market) {
      state.user_market = market;
    },
  },
};
