import axios from "axios";
import localTranslations from "../locales/en.json";

class TranslationService {
  constructor() {
    this.translations = {};
    Object.keys(localTranslations).forEach(
      (key) =>
        (this.translations[key] = {
          text: localTranslations[key],
          defaultTranslationUsed: false,
        })
    );
    this.loaded = false;
  }
  async loadTranslations() {
    try {
      const response = await axios.get(
        `/${process.env.VUE_APP_API_URI}/translations`,
        {
          headers: {
            CallSpinner: true,
          },
        }
      );
      if (response.data.success) {
        this.translations = response.data.data;
        this.loaded = true;
        return;
      }
    } catch (err) {
      console.error(err);
    }
    Object.keys(localTranslations).forEach(
      (key) =>
        (this.translations[key] = {
          text: localTranslations[key],
          defaultTranslationUsed: true,
        })
    );
    this.loaded = true;
  }
  get(key) {
    let translation = this.translations[key.toUpperCase()];
    if (!translation) {
      console.error("Key " + key + " not found");
      return key;
    }
    if (translation.defaultTranslationUsed) {
      console.warn("Default translation was used for " + key + " key");
    }
    return translation.text;
  }
}
const translationService = new TranslationService();

function t(key) {
  return translationService.get(key);
}

export { translationService, t };
