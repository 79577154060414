import state from "./state";
import axios from "axios";
import FileSaver from "file-saver";
import ContentDisposition from "content-disposition";

export default {
  namespaced: true,
  state: state,
  actions: {
    async downloadFile({ commit, rootState }, filename) {
      commit("setSpinnerState", true, { root: true });
      const response = await axios.get(
        `/${process.env.VUE_APP_API_URI}/downloads/${filename}`,
        {
          crossdomain: true,
          responseType: "blob",
          headers: {
            CallSpinner: true,
          },
        }
      );
      commit("setSpinnerState", false, { root: true });
      let disposition = ContentDisposition.parse(
        response.headers["content-disposition"]
      );
      if (
        disposition &&
        disposition.parameters &&
        disposition.parameters.filename
      )
        FileSaver.saveAs(response.data, disposition.parameters.filename);
      else FileSaver.saveAs(response.data);
    },
  }  
};