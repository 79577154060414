export default function () {
  return {
    activeWave: null,
    waves: [],
    wavesLoaded: false,
    activeWaveConfig: {
      id: 4,
      rejectionCall: false,
      shopperReaction: {
        confirmProcess: false,
        confirmProcessComment: "",
      },
      shopperTrainingAndApproval: {
        approval: false,
        comment: "",
        interesstedTrainingType: {
          id: 1,
          title: "Telephone",
          value: "telephone",
        },
      },
      recordingSetup: {
        onsiteRecording: "",
        offsiteRecording: "",
        recordingLegal: false,
        onsiteRecordingType: "",
        offsiteRecordingType: "",
        onsiteRecordingComment: "",
        offsiteRecordingComment: "",
        questionsAndRemarks: "",
        porscheFeedback: "",
        salesExecutivesNamesAllowance: false,
      },
      feedbackShopSetup: {
        onsiteFeedbackShopsInteresting: false,
        feedbackShopsLegal: false,
        onsiteFeedbackShopsType: "",
        onsiteFeedbackShopsComment: "",
        onsiteFeedbackShopsTime: "",
        questionsAndRemarks: "",
        porscheFeedback: "",
      },
      roundTableSetup: {
        roundTableInteresting: false,
        roundTableLegal: false,
        roundTableType: "",
        questionsAndRemarks: "",
        porscheFeedback: "",
      },
      bonusRelevanceSetup: {
        bonusRelevanceType: "",
        bonusRelevanceDescription: "",
        bonusRelevanceChanged: false,
        bonusRelevanceWhatChanged: "",
        bonusRelevanceWhyChanged: "",
        questionsAndRemarks: "",
      },
      localWaveSetup: {
        budgetComent: "",
        dealerParticipationComment: "",
        localWavePlanned: false,
        offsiteAgency: "",
        offsiteLocalWaves: null,
        offsiteNumberOfShopsPerPC: null,
        offsiteNumberPCIncluded: null,
        offsiteQuestionnaire: "",
        offsiteReporting: "",
        offsiteScenario: "",
        offsiteTiming: "",
        onsiteAgency: "",
        onsiteLocalWaves: null,
        onsiteNumberOfShopsPerPC: null,
        onsiteNumberPCIncluded: null,
        onsiteQuestionnaire: "",
        onsiteReporting: "",
        onsiteScenario: "",
        onsiteTiming: "",
        questionsAndRemarks: "",
      },
      timingRequirements: {
        hasTimingRequirements: false,
        reportLatestDate: null,
        firstPossibleWorkDate: null,
        comment: "",
      },
      dealerAddressSetup: {
        noChange: false,
        sendToPag: false,
        importedByBold: false
      }
    },
    interestedTrainingTypes: [],
    reportingSetUp: {
      display_target: false,
      csv_ce_target: null,
      uv_ce_target: null,
      stockcar_target: null,
      csv_ep_bev_target: null,
      csv_ep_phev_target: null,
      show_uploads: false,
      upload_mediafiles: false,
      contactdata_display: null,
    },
    waveTiming: {
      periods: [],
      waveStart: "2022-01-01",
      waveEnd: "2022-12-31",
    },
    errorModal: {
      show: false,
      title: "",
      text: "",
      okText: "",
      onOk: undefined, //Callback Function
    },
  };
}
