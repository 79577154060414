import state from "./state";
import axios from "axios";

export default {
  namespaced: true,
  state: state,
  actions: {
    getMarkets({ commit }) {
      let timeout = setTimeout(() => {
        commit("setSpinnerState", true, { root: true });
      }, 300);

      return axios
        .get(process.env.VUE_APP_API_URI + `/Markets`, {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        })
        .then((response) => {
          commit("setMarkets", response.data.data);
          commit("setSpinnerState", false, { root: true });
          clearTimeout(timeout);
        })
        .catch(() => {
          clearTimeout(timeout);
        });
    },
  },
  getters: {
    getMarkets(state) {
      return state.markets;
    },
  },
  mutations: {
    setMarkets(state, markets) {
      state.markets = markets;
    },
  },
};
