import state from "./state";
import axios from "axios";
import store from "../index";

export default {
  namespaced: true,
  state: state,
  actions: {
    async loadSections({ rootState, commit }) {
      const response = await axios.get(
        "/" +
          process.env.VUE_APP_API_URI +
          `/configurations/${rootState.configurations.currentConfiguration.id}/sections`,
        {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        }
      );
      commit("setSections", response.data.data);
      return response.data.data;
    },
    /**
     * @deprecated since 12.03.22
     * @param {import("vuex").ActionContext} context
     * @param {*} section
     * @returns
     */
    updateSection(context, section) {
      if (section.name === "submit" || section.name === "approve") {
        return false;
      }
      if (context.rootGetters["configurations/currentConfigurationReadonly"]) {
        return false;
      }

      const _ = {
        sectionId: section.id,
        configId: context.rootState.configurations.currentConfiguration.id,
      };
      return axios
        .put(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configId}/sections/${_.sectionId}`,
          section,
          {
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          context.commit("updateSection", response.data.data);
          return response.data.data;
        });
    },
  },
  getters: {
    getActiveSection(state) {
      return state.sections.find(
        (element) => element.position === state.activeSectionPositon
      );
    },
    allSections(state) {
      return state.sections;
    },
    prevSection(state) {
      const currentStepIndex = state.sections.findIndex(
        (element) => element.position === state.activeSectionPositon
      );
      if (currentStepIndex - 1 >= 0) {
        return state.sections[currentStepIndex - 1];
      } else {
        return null;
      }
    },
    nextSection(state) {
      const currentStepIndex = state.sections.findIndex(
        (element) => element.position === state.activeSectionPositon
      );
      if (currentStepIndex + 1 <= state.sections.length) {
        return state.sections[currentStepIndex + 1];
      } else {
        return null;
      }
    },
  },
  mutations: {
    setSections(state, sections) {
      if (store.getters["users/isAdminUser"]) {
        sections.push({
          id: sections[sections.length - 1].id - 1,
          position: sections[sections.length - 1].position + 1,
          title: "Approve",
          name: "approve",
          checked:
            store.getters["configurations/getCurrentConfiguration"].status ===
            "approved",
        });
      } else {
        sections.push({
          id: sections[sections.length - 1].id - 1,
          position: sections[sections.length - 1].position + 1,
          title: "Submit",
          name: "submit",
          checked:
            store.getters["configurations/getCurrentConfiguration"].status ===
            "pending",
        });
      }
      state.sections = sections;

      let index = 0;
      let selectedPosition = 1;
      if (sections[0].checked) {
        do {
          index = index + 1;
          selectedPosition = sections[index].position;
        } while (index < sections.length - 1 && sections[index].checked);
      }
      this.commit("offSiteSections/setActiveSectionPosition", selectedPosition);
    },
    setActiveSectionPosition(state, id) {
      // redirect to section cover stories when storycount is 0
      if(store.getters["stories/getStoryCount"] == 0) {
        state.activeSectionPositon = 1;
      } else {
        state.activeSectionPositon = id;
      }
    },
    updateSection(state, updatedStep) {
      const index = state.sections.findIndex(
        (element) => element.position == updatedStep.position
      );
      state.sections[index] = updatedStep;
    },
    checkedSection(state, id) {
      let section = state.sections.find((element) => element.position === id);
      section.checked = true;

      this.dispatch("offSiteSections/updateSection", section);
    },
    setnextSection(state) {
      if (state.activeSectionPositon >= state.sections.length)
        throw new Error("Already at last Section");
      this.commit("offSiteSections/checkedSection", state.activeSectionPositon);
      this.commit(
        "offSiteSections/setActiveSectionPosition",
        state.activeSectionPositon + 1
      );
    },
    setprevSection(state) {
      if (state.activeSectionPositon < 2)
        throw new Error("Already at first Section");

      this.commit(
        "offSiteSections/setActiveSectionPosition",
        state.activeSectionPositon - 1
      );
    },
  },
};
