import axios from "axios";
import dev from "./configs/dev.json";
import stage from "./configs/stage.json";
import prod from "./configs/prod.json";
import store from "./../store";

export default {

  auth: false,

  init: false,

  configureAxiosSpinner() {
    axios.interceptors.request.use(
      (conf) => {
        if (conf.headers["CallSpinner"]) {
          store.commit("addSpinner");
        }
        return conf;
      },
      (err) => Promise.reject(err)
    );

    axios.interceptors.response.use(
      (conf) => {
        store.commit("lowerSpinner");
        return conf;
      },
      async (error) => {
        store.commit("lowerSpinner");
        return Promise.reject(error);
      }
    );
  },

  getKeycloakConfig() {
    if (process.env.NODE_ENV === 'production') {
      return prod;
    }
    if (process.env.NODE_ENV === 'staging') {
      return stage;
    }
    return dev;
  },

  configureAxiosSetup() {
    let access_token = this.getAccessToken();
    if (!access_token) {
      console.error("No access token available");
      return;
    }
    axios.defaults.headers.common = { Authorization: `Bearer ${access_token}` };
  },
  getAccessToken() {
    return localStorage.getItem("access_token") || null;
  },
  getRefreshToken() {
    return localStorage.getItem("refresh_token") || null;
  },
  getIdToken() {
    return localStorage.getItem("id_token") || null;
  },
  storeTokensInStorage(access_token, id_token, refresh_token) {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("id_token", id_token);
    localStorage.setItem("refresh_token", refresh_token);
  },
  removeTokensFromStorage() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");
  },
}