import state from "./state";
import axios from "axios";
import FileSaver from "file-saver";
import ContentDisposition from "content-disposition";

export default {
  namespaced: true,
  state: state,
  actions: {
    async loadConfiguration(context, configId) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setCurrentConfiguration", response.data.data);
          if (response.data.data.onSite) {
            await context.dispatch("onSiteSections/loadSections", null, {
              root: true,
            });
          } else {
            await context.dispatch("offSiteSections/loadSections", null, {
              root: true,
            });
          }
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async addNewConfiguration(context, onsite) {
      const config = {
        waveGuid: context.rootState.waves.activeWave.guid,
        onSite: onsite ? true : false,
      };
      try {
        const response = await axios.post(
          `/${process.env.VUE_APP_API_URI}/configurations`,
          config,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          if (onsite) {
            context.commit("addNewOnSiteConfiguration", response.data.data);
          } else {
            context.commit("addNewOffSiteConfiguration", response.data.data);
          }
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadOnSiteConfigurations({ commit, rootState }) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/Waves/${rootState.waves.activeWave.guid}/configurations/onsite`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          commit("setOnSiteConfigurations", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadOffSiteConfigurations({ commit, rootState }) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/Waves/${rootState.waves.activeWave.guid}/configurations/offsite`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          commit("setOffSiteConfigurations", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async setupConfiguration(context, config) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${config.id}/setup`,
          {
            scenarioId: config.scenarioId,
            contactWayId: config.contactWayId,
            testDrive: config.testDrive,
            otherContactWay: config.otherContactWay
          },
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateConfiguration", response.data.data);
          context.commit("setDuplicateErrorConfigurationId", -1);
        } else {
          context.commit("setDuplicateErrorConfigurationId", config.id);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateConfiguration(context, config) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${config.id}/update`,
          {
            model: config.model,
            notAllowedType: config.notAllowedType,
            allowedType: config.allowedType,
            contactSpecifics: config.contactSpecifics,
            preparedWave: config.preparedWave
          },
          {
            headers: {
              CallSpinner: false,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateObjection({ state, commit }, data) {
      const config = state.currentConfiguration;
      if (
        data.objection === config.objection
      ) {
        return;
      }
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${config.id}/objection`,
          data
        );
        if (response.data.success) {
          commit("updateActiveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateOverallComment({ state, commit }, data) {
      const config = state.currentConfiguration;
      if (
        data.overallComment === config.overallComment
      ) {
        return;
      }
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${config.id}/overallComment`,
          data
        );
        if (response.data.success) {
          commit("updateActiveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async resetConfiguration(context, configId) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}/reset`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deleteConfiguration(context, configId) {
      try {
        const response = await axios.delete(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("deleteOnSiteConfigurations", configId);
          context.commit("deleteOffSiteConfigurations", configId);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },

    // ----- Status change section -----
    async startConfiguration(context, configId) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}/start`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateActiveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async approveConfig(context, configId) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}/approve`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateActiveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async rejectConfig(context, configId) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}/reject`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateActiveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async submitConfig(context, configId) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/configurations/${configId}/submit`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("updateActiveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },

    // ----- Download excel report -----
    async getExcelReport({ commit, state }, onSite) {
      commit("setSpinnerState", true, { root: true });
      const { id: configId } = state.currentConfiguration;
      const response = await axios.get(
        `/${process.env.VUE_APP_API_URI}/configurations/${configId}/report`,
        {
          crossdomain: true,
          responseType: "blob",
          headers: {
            CallSpinner: true,
          },
        }
      );
      commit("setSpinnerState", false, { root: true });
      let disposition = ContentDisposition.parse(
        response.headers["content-disposition"]
      );
      if (
        disposition &&
        disposition.parameters &&
        disposition.parameters.filename
      )
        FileSaver.saveAs(response.data, disposition.parameters.filename);
      else FileSaver.saveAs(response.data);
    },

    // ----- Load sections and contact ways -----
    async getScenariosAction(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/scenarios`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setScenarios", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadAllOnSiteContactWays(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/contactways?onsite=true`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setOnSiteContactWays", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadAllOffSiteContactWays(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/contactways?onsite=false`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setOffSiteContactWays", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getCurrentConfiguration(state) {
      return state.currentConfiguration;
    },
    getDuplicateErrorConfigurationId(state) {
      return state.duplicateErrorConfigurationId;
    },
    currentConfigurationInProgress(state) {
      return state.currentConfiguration.status === "inprogress";
    },
    currentConfigurationPending(state) {
      return state.currentConfiguration.status === "pending";
    },
    currentConfigurationApproved(state) {
      return state.currentConfiguration.status === "approved";
    },
    currentConfigurationReadonly(state, getters, rootState, rootGetters) {
      let config = state.currentConfiguration;
      if (rootGetters["waves/getActiveWaveReadonly"]) {
        return true;
      }
      if (config.status === "pending") {
        return rootGetters["users/isMarketOrAgencyUser"];
      }
      if (config.status === "inprogress") {
        return rootGetters["users/isAdminUser"];
      }
      return true;
    },
    getScenarios(state) {
      return state.scenarios;
    },
    getOnSiteContactWays(state) {
      return state.onsite_contact_ways;
    },
    getOffSiteContactWays(state) {
      return state.offsite_contact_ways;
    },
    getOnSiteConfigurations(state) {
      return state.on_site_configurations;
    },
    getOffSiteConfigurations(state) {
      return state.off_site_configurations;
    },
    getCurrentContactway(state, getters) {
      if (!state.currentConfiguration.contactWayId) return undefined;
      const id = state.currentConfiguration.contactWayId;
      if (getters.isOffSite) {
        return getters.getOffSiteContactWays.find((el) => el.id === id);
      } else {
        return getters.getOnSiteContactWays.find((el) => el.id === id);
      }
    },
    getCurrentScenario(state, getters) {
      if (!state.currentConfiguration.scenarioId) return undefined;
      const id = state.currentConfiguration.scenarioId;
      return getters.getScenarios.find((el) => el.id === id);
    },
    isOffSite(state) {
      return state.currentConfiguration?.type === "off_site_configurations";
    },
    isOnSite(state) {
      return state.currentConfiguration?.onSite;
    },
    getCurrentContactwayName(state, getters) {
      return getters.getCurrentContactway?.title;
    },
    getCurrentScenarioName(state, getters) {
      if (getters.isOffSite) return getters.getCurrentContactwayName;

      return getters.getCurrentScenario?.title;
    },
    getConfigurationDescription(state, getters) {
      const scenario = getters.getCurrentScenario?.title;
      const testDrive = state.currentConfiguration?.testDrive
        ? "Test Drive"
        : "Not Test Drive";
      const contactWay = getters.getCurrentContactway?.title;

      if (getters.isOnSite) {
        return `${scenario} - ${testDrive} - ${contactWay}`;
      }
      return `${contactWay}`;
    },
    allObjectionsOptions(state, getters) {
      let currentScenario = getters.getCurrentScenario;
      if (!currentScenario) return [];

      return state.objectionOptions.filter((objection) => {
        return (
          objection.scenarios.includes(currentScenario.value) ||
          objection.scenarios.includes("all-scenarios")
        );
      });
    },
  },
  mutations: {
    setDuplicateErrorConfigurationId(state, duplicateErrorConfigurationId) {
      state.duplicateErrorConfigurationId = duplicateErrorConfigurationId;
    },
    addNewOnSiteConfiguration(state, on_site_configuration) {
      state.on_site_configurations.push(on_site_configuration);
    },
    addNewOffSiteConfiguration(state, off_site_configuration) {
      state.off_site_configurations.push(off_site_configuration);
    },
    setOnSiteConfigurations(state, on_site_configurations) {
      state.on_site_configurations = on_site_configurations;
    },
    setOffSiteConfigurations(state, off_site_configurations) {
      state.off_site_configurations = off_site_configurations;
    },
    setScenarios(state, scenarios) {
      state.scenarios = scenarios;
    },
    setOnSiteContactWays(state, onsite_contact_ways) {
      state.onsite_contact_ways = onsite_contact_ways;
    },
    setOffSiteContactWays(state, offsite_contact_ways) {
      state.offsite_contact_ways = offsite_contact_ways;
    },
    setCurrentConfiguration(state, config) {
      state.currentConfiguration = config;
    },
    resetCurrentConfiguration(state) {
      state.currentConfiguration = null;
      state.off_site_configurations = [];
      state.on_site_configurations = [];
    },
    deleteOnSiteConfigurations(state, id) {
      state.on_site_configurations = state.on_site_configurations.filter(
        (c) => c.id !== id
      );
    },
    deleteOffSiteConfigurations(state, id) {
      state.off_site_configurations = state.off_site_configurations.filter(
        (c) => c.id !== id
      );
    },
    updateActiveConfiguration(state, config) {
      state.currentConfiguration = config;
    },
    updateConfiguration(state, config) {
      let idx = state[config.type].findIndex((c) => c.id === config.id);
      state[config.type] = [
        ...state[config.type].slice(0, idx),
        Object.assign(state[config.type][idx], config),
        ...state[config.type].slice(idx + 1),
      ];
    },
  },
};
