import axios from "axios";
import { sassTrue } from "sass";
import state from "./state";

export default {
  namespaced: true,
  state: state,
  actions: {
    async loadWaves({ commit, rootGetters }) {
      const projectGuid = rootGetters["projects/getActiveProject"].guid;
      await axios
        .get(`/${process.env.VUE_APP_API_URI}/projects/${projectGuid}/waves`, {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        })
        .then((response) => {
          commit("setWaves", response.data.data);
        });
    },
    async setActiveWave({ dispatch, getters, commit }, waveGuid) {
      try {
        if (getters["getWavesLoaded"] == false) {
          await dispatch("loadWaves");
        }
        if (getters["getActiveWaveGuid"] === waveGuid) {
          return;
        }
        commit("setActiveWave", waveGuid);
        if (getters["getActiveWaveGuid"] === null) {
          return;
        }
        await dispatch("loadWaveConfiguration");
        await dispatch("getLanguages", waveGuid, { root: true });
        commit("configurations/resetCurrentConfiguration", null, {
          root: true,
        });
        await dispatch("configurations/loadAllOffSiteContactWays", null, {
          root: true,
        });
        await dispatch("configurations/getScenariosAction", null, {
          root: true,
        });
        await dispatch("configurations/loadAllOnSiteContactWays", null, {
          root: true,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async reloadWaveWaveConfiguration({ getters, dispatch }) {
      if (getters["getActiveWave"]) {
        await dispatch("loadWaveConfiguration");
      }
    },
    async loadWaveConfiguration({ commit, getters }) {
      const waveGuid = getters["getActiveWaveGuid"];
      return axios
        .get(
          `/${process.env.VUE_APP_API_URI}/waves/${waveGuid}/waveconfiguration`,
          {
            crossdomain: true,
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          commit("setActiveWaveConfiguration", response.data.data);
        });
    },
    async loadInterestedTrainingTypes(context) {
      await axios
        .get(`/${process.env.VUE_APP_API_URI}/InterestedTrainingTypes`, {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        })
        .then((response) => {
          context.commit("setInterestedTrainingTypes", response.data.data);
        })
        .catch((ex) => {
          throw ex;
        });
    },
    async loadReportingSetUp(context) {
      await axios
        .get(
          `/${process.env.VUE_APP_API_URI}/Waves/${context.state.activeWave.guid}/Reporting`,
          {
            crossdomain: true,
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          context.commit("setReportingSetUp", response.data.data);
        });
    },
    async setShopperTrainingAndApproval(context, shopper_training) {
      await axios
        .put(
          `/${process.env.VUE_APP_API_URI}/Waves/${context.rootState.waves.activeWave.guid}/ShopperTrainingAndApproval`,
          shopper_training
        )
        .then((response) => {
          context.commit("setActiveWaveConfiguration", response.data.data);
        });
    },
    async setShopperReaction(context, shopper_reaction) {
      await axios
        .put(
          `/${process.env.VUE_APP_API_URI}/Waves/${context.rootState.waves.activeWave.guid}/ShopperReaction`,
          shopper_reaction
        )
        .then((response) => {
          context.commit("setActiveWaveConfiguration", response.data.data);
        });
    },
    async setWaveTiming(context, wave_timing) {
      let boundTiming = function (value, min, max) {
        return String(
          Number(value) >= Number(min)
            ? value <= Number(max)
              ? Number(value)
              : Number(max)
            : Number(min)
        );
      };
      try {
        wave_timing.timeFrameStart = wave_timing.timeFrameStart
          ? boundTiming(wave_timing.timeFrameStart, 1, wave_timing.timeFrameEnd)
          : undefined;
        wave_timing.timeFrameEnd = wave_timing.timeFrameEnd
          ? boundTiming(
              wave_timing.timeFrameEnd,
              wave_timing.timeFrameStart,
              53
            )
          : undefined;
        wave_timing.reportDeadline = wave_timing.reportDeadline
          ? boundTiming(wave_timing.reportDeadline, 1, 53)
          : undefined;
        wave_timing.periods.forEach((period) => {
          period.end = period.end
            ? boundTiming(period.end, period.start, 53)
            : undefined;
          period.start = period.start
            ? boundTiming(period.start, 1, period.end)
            : undefined;
        });
        let response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/Waves/${context.rootState.waves.activeWave.guid}/WaveTiming`,
          wave_timing
        );
        if (response.data.success) {
          context.commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async setReportingSetUp(context, reporting_setup) {
      await axios
        .put(
          `/${process.env.VUE_APP_API_URI}/Waves/${context.state.activeWave.guid}/Reporting`,
          reporting_setup
        )
        .then((response) => {
          context.commit("setReportingSetUp", response.data.data);
        });
    },
    async setAppealManagement(context, appeal_management) {
      const response = await axios.put(
        `/${process.env.VUE_APP_API_URI}/waves/${context.state.activeWave.guid}/appealManagement`,
        appeal_management
      );
      context.commit("setActiveWaveConfiguration", response.data.data);
    },
    async updateRecordingSetup({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/recordingSetup`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateFeedbackShopSetup({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/feedbackShopSetup`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateRoundTableSetup({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/roundTableSetup`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateTimingRequirements({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/timingRequirements`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateBonusRelevanceSetup({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/bonusRelevanceSetup`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateLocalWaveSetup({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/localWaveSetup`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadWaveTiming({ commit, state }) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/waveTiming`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          commit("setWaveTiming", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateWaveTiming({ commit, state, dispatch }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/waveTiming`,
          model
        );
        if (response.data.success) {
          commit("setWaveTiming", response.data.data);
        } else {
          await dispatch("loadWaveTiming");
          console.error(response.data.message);
        }
      } catch (err) {
        await dispatch("loadWaveTiming");
        console.error(err);
      }
    },
    async updateWaveTimingPeriod({ commit, state, dispatch }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/waveTiming/periodWeek`,
          model
        );
        if (response.data.success) {
          commit("setWaveTiming", response.data.data);
        } else {
          await dispatch("loadWaveTiming");
          console.error(response.data.message);
        }
      } catch (err) {
        await dispatch("loadWaveTiming");
        console.error(err);
      }
    },
    async updateWaveTimingPeriodDate({ commit, state, dispatch }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/waveTiming/periodDate`,
          model
        );
        if (response.data.success) {
          commit("setWaveTiming", response.data.data);
        } else {
          await dispatch("loadWaveTiming");
          console.error(response.data.message);
        }
      } catch (err) {
        await dispatch("loadWaveTiming");
        console.error(err);
      }
    },
    async updateDealerAddressSetup({ commit, state }, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/dealerAddressSetup`,
          model
        );
        if (response.data.success) {
          commit("setActiveWaveConfiguration", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateRejectionCall({ commit, state }, rejectioncall) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/waves/${state.activeWave.guid}/rejectioncall/${rejectioncall}`,
        );
        if (response.data.success) {
          state.activeWaveConfig.rejectionCall = rejectioncall;
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getWaves(state) {
      return state.waves.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    getWavesLoaded(state) {
      return state.wavesLoaded;
    },
    getActiveWaveGuid(state) {
      return state.activeWave ? state.activeWave.guid : null;
    },
    getActiveWave(state) {
      return state.activeWave;
    },
    getActiveWaveReadonly(state) {
      return (
        (state.activeWave.isDepricated ?? false) ||
        state.activeWave.status === "Done"
      );
    },
    getActiveWaveConfig(state) {
      return state.activeWaveConfig;
    },
    getShopperTrainingAndApproval(state) {
      return state.activeWaveConfig.shopperTrainingAndApproval;
    },
    getShopperReaction(state) {
      return state.activeWaveConfig.shopperReaction;
    },
    getInterestedTrainingTypes(state) {
      return state.interestedTrainingTypes;
    },
    loadReportingSetUp(state) {
      return state.reportingSetUp;
    },
    getRecordingSetup(state) {
      return state.activeWaveConfig.recordingSetup;
    },
    getFeedbackShopSetup(state) {
      return state.activeWaveConfig.feedbackShopSetup;
    },
    getRoundTableSetup(state) {
      return state.activeWaveConfig.roundTableSetup;
    },
    getTimingRequirements(state) {
      return state.activeWaveConfig.timingRequirements;
    },
    getBonusRelevanceSetup(state) {
      return state.activeWaveConfig.bonusRelevanceSetup;
    },
    getLocalWaveSetup(state) {
      return state.activeWaveConfig.localWaveSetup;
    },
    getDealerAddressSetup(state) {
      return state.activeWaveConfig.dealerAddressSetup;
    },
    getErrorModal(state) {
      return state.errorModal;
    },
    getWaveTiming(state) {
      return state.waveTiming;
    },
    getWavePeriods(state) {
      return state.waveTiming.periods;
    },
    getRejectionCall(state) {
      return state.activeWaveConfig.rejectionCall;
    },
  },
  mutations: {
    setErrorModal(state, data) {
      state.errorModal.show = data.show;
      state.errorModal.title = data.title;
      state.errorModal.text = data.text;
      state.errorModal.onOk = data.onOk;
      state.errorModal.okText = data.okText;
    },
    setActiveWave(state, waveGuid) {
      let wave = state.waves.find((v) => v.guid === waveGuid);
      state.activeWave = wave;
    },
    setWaves(state, waves) {
      state.waves = waves;
      state.wavesLoaded = true;
    },
    setWaveTiming(state, waveTiming) {
      state.waveTiming = waveTiming;
    },
    setActiveWaveConfiguration(state, configuration) {
      state.activeWaveConfig = configuration;
    },
    setInterestedTrainingTypes(state, interestedTrainingTypes) {
      state.interestedTrainingTypes = interestedTrainingTypes;
    },
    setInterestedTrainingType(state, interestedTrainingType) {
      return state.waves.forEach((w) => {
        if (w.guid === state.activeWave.guid) {
          w.configuration.shopperTrainingAndApproval.interestedTrainingType = interestedTrainingType;
        }
      });
    },
    setReportingSetUp(state, reportingSetUp) {
      state.reportingSetUp = reportingSetUp;
    },
    resetActiveWave(state) {
      state.activeWave = null;
      state.activeWaveConfig = null;
      state.getWaveTiming = null;
    },
  },
};
