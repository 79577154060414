import state from "./state";
import axios from "axios";
import FileSaver from "file-saver";
import ContentDisposition from "content-disposition";

export default {
  namespaced: true,
  state: state,
  actions: {
    conductAllDealerPerTest({ rootState, commit }, { mode, checked }) {
      const _ = {
        configid: rootState.configurations.currentConfiguration.id,
        checkaction: checked ? "check" : "uncheck",
      };
      return axios
        .put(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configid}/numberOfTestsPerDealer/all/${_.checkaction}`,
          {},
          {
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          if (mode === "on-site") {
            commit("setAllOnSiteDealersPerTests", response.data.data);
          } else if (mode === "off-site") {
            commit("setAllOffSiteDealersPerTests", response.data.data);
          }
        });
    },
    async numberOfTestAllDealer({ commit, rootState }, { mode, checked }) {
      const _ = {
        number_of_test: checked ? 2 : 1,
        configid: rootState.configurations.currentConfiguration.id,
      };
      const response = await axios.put(
        `/${process.env.VUE_APP_API_URI}/configurations/${_.configid}/numberOfTestsPerDealer/all/${_.number_of_test}`,
        {},
        {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        }
      );
      if (mode === "on-site") {
        commit("setAllOnSiteDealersPerTests", response.data.data);
      } else if (mode === "off-site") {
        commit("setAllOffSiteDealersPerTests", response.data.data);
      }
    },
    getDealersPerTest({ rootState, commit }, mode) {
      const _ = {
        configid: rootState.configurations.currentConfiguration.id,
      };
      return axios
        .get(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configid}/numberOfTestsPerDealer`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          if (mode === "on-site") {
            commit("setAllOnSiteDealersPerTests", response.data.data);
          } else if (mode === "off-site") {
            commit("setAllOffSiteDealersPerTests", response.data.data);
          }
        });
    },
    saveDealersPerTest({ rootState, commit }, { mode, dealer }) {
      const _ = {
        partnerId: dealer.partnerId,
        configId: rootState.configurations.currentConfiguration.id,
      };

      return axios
        .put(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configId}/numberOfTestsPerDealer/${_.partnerId}`,
          dealer,
          {
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          if (mode === "on-site") {
            commit("updateOnSiteDealerPerTests", response.data.data);
          } else if (mode === "off-site") {
            commit("updateOffSiteDealerPerTests", response.data.data);
          }
        });
    },
    async loadDealers({ commit, rootState }) {
      commit("setAllDealers", []);
      await axios
        .get(
          `/${process.env.VUE_APP_API_URI}/Waves/${rootState.waves.activeWave.guid}/Locations`,
          {
            crossdomain: true,
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          commit("setAllDealers", response.data.data);
        });
    },
    // flips a single bool
    editDealer(context, dealer) {
      axios.put(
        `/${process.env.VUE_APP_API_URI}/Waves/${context.rootState.waves.activeWave.guid}/Locations/${dealer.id}`,
        dealer
      );
    },
    async checkAllDealers({ commit, rootState }, check_all) {
      try {
        let response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/Waves/${rootState.waves.activeWave.guid}/Locations?checkAll=${check_all}`,
          {},
          {
            crossdomain: true,
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success === false) {
          console.error(response.data.message);
        }
        commit("setAllDealers", response.data.data);
      } catch (err) {
        console.error(err);
      }
    },
    downloadFile(context, { fileName, downloadLink }) {
      axios
        .get(downloadLink, {
          crossdomain: true,
          responseType: "arraybuffer",
        })
        .then((response) => {
          const objectUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const target = document.createElement("a");
          target.href = objectUrl;
          target.download = fileName;
          target.click();
          window.URL.revokeObjectURL(objectUrl);
        });
    },
    
    // ----- Download excel report -----
    async getLocationReport({ commit, rootState }) {
      commit("setSpinnerState", true, { root: true });
      const response = await axios.get(
        `/${process.env.VUE_APP_API_URI}/Waves/${rootState.waves.activeWave.guid}/report`,
        {
          crossdomain: true,
          responseType: "blob",
          headers: {
            CallSpinner: true,
          },
        }
      );
      commit("setSpinnerState", false, { root: true });
      let disposition = ContentDisposition.parse(
        response.headers["content-disposition"]
      );
      if (
        disposition &&
        disposition.parameters &&
        disposition.parameters.filename
      )
        FileSaver.saveAs(response.data, disposition.parameters.filename);
      else FileSaver.saveAs(response.data);
    },
  },
  getters: {
    getAllDealers(state) {
      return state.dealers;
    },
    getAllOffSiteDealersPerTests(state) {
      return state.off_site_dealers_per_tests;
    },
    getAllOnSiteDealersPerTests(state) {
      return state.on_site_dealers_per_tests;
    },
    getError(state) {
      return state.error;
    },
  },
  mutations: {
    setError(state, value) {
      return (state.error = value);
    },
    setAllDealers(state, dealers) {
      state.dealers = dealers;
    },
    setAllOnSiteDealersPerTests(state, dealers) {
      state.on_site_dealers_per_tests = dealers;
    },
    setAllOffSiteDealersPerTests(state, dealers) {
      state.off_site_dealers_per_tests = dealers;
    },
    updateOnSiteDealerPerTests(state, dealer) {
      const index = state.on_site_dealers_per_tests.findIndex(
        (element) => dealer.id === element.id
      );

      state.on_site_dealers_per_tests[index] = dealer;
    },
    updateOffSiteDealerPerTests(state, dealer) {
      const index = state.off_site_dealers_per_tests.findIndex(
        (element) => dealer.id === element.id
      );

      state.off_site_dealers_per_tests[index] = dealer;
    },
  },
};
