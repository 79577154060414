import axios from "axios";
import state from "./state";

export default {
  namespaced: true,
  state: state,
  getters: {
    getStories(state) {
      return state.stories;
    },
    getStoryCount(state) {
      var count = 0;
      state.stories.forEach(story => {
        if(story.englishText || story.localText) count++;
      });
      return count;
    }
  },
  actions: {
    async saveStoryTexts(context, story) {
      try {
        let response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/coverstory`,
          story
        );
        if (response.data.success === false) {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    getStories(context) {
      return axios
        .get(
          `/${process.env.VUE_APP_API_URI}/coverstory/stories/${context.rootState.configurations.currentConfiguration.id}`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          context.commit("saveStories", response.data.data);
        });
    },
  },
  mutations: {
    saveStoriesTexts(state, stories) {
      state.stories = stories;
    },
    saveStories(state, stories) {
      state.stories = stories;
    },
  },
};
