import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";
import { translationService } from "@/services/translationService";
import configurationLockService from "@/services/configurationLockService";
import appService from "../services/appService.js";

const Configuration = () => import("../views/Configuration.vue");
const GeneralSetup = () => import("../views/GeneralSetup.vue");
const Projects = () => import("../views/Projects.vue");
const DealerAdresses = () => import("../views/DealerAdresses.vue");
const ConfigurationView = () => import("../views/ConfigurationView.vue");
const AppealManagement = () => import("../views/AppealManagement.vue");
const ReportingSetUp = () => import("../views/ReportingSetUp.vue");
const WavesSelection = () => import("../views/WaveSelection.vue");
const Translations = () => import("../views/Translations.vue");
const Logs = () => import("../views/Logs.vue");

const routes = [
  {
    path: "/",
    redirect: "/projects",
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/projects/:projectGuid/waves",
    component: WavesSelection,
  },
  {
    path: "/projects/:projectGuid/waves/:waveGuid/general-setup",
    component: GeneralSetup,
  },
  {
    path: "/projects/:projectGuid/waves/:waveGuid/dealer-adresses",
    component: DealerAdresses,
  },
  {
    path: "/projects/:projectGuid/waves/:waveGuid/appeal-management",
    component: AppealManagement,
  },
  {
    path: "/projects/:projectGuid/waves/:waveGuid/reporting-setup",
    component: ReportingSetUp,
  },
  {
    path: "/projects/:projectGuid/waves/:waveGuid/configurations",
    component: Configuration,
  },
  {
    path:
      "/projects/:projectGuid/waves/:waveGuid/configurations/:configurationId",
    component: ConfigurationView,
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
  },
  {
    path: "/translations",
    name: "Translations",
    component: Translations,
  },
  {
    path: "/callback",
    name: "DirectToLoginCallback",
  },
  {
    path: "/logout_callback",
    name: "DirectToLogoutCallback",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/projects",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {

  if(!appService.auth) {
    return false;
  }

  if (!appService.init) {
    await store.dispatch("loadVersion");
    await store.dispatch("users/loadUser");
    await store.dispatch("users/loadUserMarket");
    await translationService.loadTranslations();
    appService.init = true;
    await configurationLockService.start();
  }

  if (to.path === "/translations" || to.path === "/logs") {
    if (store.getters["users/isAdminUser"]) {
      next();
      return;
    } else {
      next({ path: "/projects" });
      return;
    }
  }

  if (to.params.projectGuid) {
    await store.dispatch("projects/setActiveProject", to.params.projectGuid);
    if (store.getters["projects/getActiveProjectGuid"] === null) {
      next({ path: "/projects" });
      return;
    }
  }

  if (to.params.projectGuid && to.params.waveGuid) {
    await store.dispatch("waves/setActiveWave", to.params.waveGuid);
    if (store.getters["waves/getActiveWaveGuid"] === null) {
      next({ path: `/projects/${to.params.projectGuid}/waves` });
      return;
    }
  }

  if (
    to.params.projectGuid &&
    to.params.waveGuid &&
    to.params.configurationId
  ) {
    await store.dispatch(
      "configurations/loadConfiguration",
      to.params.configurationId
    );
    if (store.getters["confiugrations/getCurrentConfiguration"] === null) {
      next({
        path: `/projects/${to.params.projectGuid}/waves/${to.params.projectGuid}/configurations`,
      });
      return;
    }
  }

  next();
});

export default router;
