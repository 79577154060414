import state from "./state";
import axios from "axios";

export default {
  namespaced: true,
  state: state,
  actions: {
    loadQuestions({ commit, rootState }, { sectionId, languages }) {
      const _ = {
        sectionId: parseInt(sectionId),
        languagecodes: languages,
        configId: rootState.configurations.currentConfiguration.id,
      };

      return axios
        .get(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configId}/sections/${_.sectionId}/questions?languages=${_.languagecodes}`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        )
        .then((response) => {
          commit("setQuestions", response.data.data);
          return response.data.data;
        });
    },
    async checkQuestion(context, question) {
      const _ = {
        sectionid: context.rootGetters["onSiteSections/getActiveSection"].id,
        questionId: question.id,
        configid: context.rootState.configurations.currentConfiguration.id,
      };

      try {
        const response = await axios.post(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configid}/sections/${_.sectionid}/questions/${_.questionId}`,
          {}
        );
        if (response.data.success) {
          question.checked = true;
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
      context.commit("renewQuestions");
    },
    async uncheckQuestion(context, question) {
      const _ = {
        sectionid: context.rootGetters["onSiteSections/getActiveSection"].id,
        questionId: question.id,
        configid: context.rootState.configurations.currentConfiguration.id,
      };

      try {
        const response = await axios.delete(
          `/${process.env.VUE_APP_API_URI}/configurations/${_.configid}/sections/${_.sectionid}/questions/${_.questionId}`
        );
        if (response.data.success) {
          question.checked = false;
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
      context.commit("renewQuestions");
    },
  },
  getters: {
    getAllQuestions(state) {
      return state.questions;
    },
  },
  mutations: {
    setQuestions(state, questions) {
      state.questions = questions;
    },
    renewQuestions(state) {
      state.questions = [...state.questions];
    },
  },
};
