import state from "./state";
import axios from "axios";

export default {
  namespaced: true,
  state: state,
  actions: {
    async loadActiveLanguages(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/translations/languages/active`
        );
        if (response.data.success) {
          context.commit("setActiveLanguages", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadLanguages(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/translations/languages`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setLanguages", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async addLanguage(context, model) {
      try {
        const response = await axios.post(
          `/${process.env.VUE_APP_API_URI}/translations/languages`,
          model,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          await context.dispatch("loadLanguages");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateLanguage(context, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/translations/languages`,
          model
        );
        if (response.data.success) {
          await context.dispatch("loadLanguages");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadTags(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/translations/tags`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setTags", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async addTag(context, model) {
      try {
        const response = await axios.post(
          `/${process.env.VUE_APP_API_URI}/translations/tags`,
          model,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          await context.dispatch("loadTags");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateTag(context, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/translations/tags`,
          model
        );
        if (response.data.success) {
          await context.dispatch("loadTags");
          await context.dispatch("loadTranslations");
          await context.dispatch("loadDefaultTranslations");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async deleteTag(context, id) {
      try {
        const response = await axios.delete(
          `/${process.env.VUE_APP_API_URI}/translations/tags/${id}`
        );
        if (response.data.success) {
          await context.dispatch("loadTags");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadTranslations(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/translations/raw`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setTranslations", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadDefaultTranslations(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/translations/default`,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setDefaultTranslations", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateTranslation(context, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/translations`,
          model
        );
        if (response.data.success) {
          await context.dispatch("loadTranslations");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async importTranslations(context, model) {
      try {
        const response = await axios.put(
          `/${process.env.VUE_APP_API_URI}/translations/import`,
          model
        );
        if (response.data.success) {
          await context.dispatch("loadTranslations");
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    },
    getActiveLanguages(state) {
      return state.activeLanguages;
    },
    getTags(state) {
      return state.tags;
    },
    getTranslations(state) {
      return state.translations;
    },
    getDefaultTranslations(state) {
      return state.defaultTranslations;
    },
  },
  mutations: {
    setActiveLanguages(state, activeLanguages) {
      state.activeLanguages = activeLanguages;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setTranslations(state, translations) {
      state.translations = translations;
    },
    setDefaultTranslations(state, defaultTranslations) {
      state.defaultTranslations = defaultTranslations;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
  },
};
