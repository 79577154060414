export default function () {
  return {
    duplicateErrorConfigurationId: -1,
    scenarios: [],
    onsite_contact_ways: [],
    offsite_contact_ways: [],
    currentConfiguration: null,
    on_site_configurations: [],
    off_site_configurations: [],
    objectionOptions: [
      {
        scenarios: ["stock_car"],
        objection:
          "You think that too many configurations are selected leading to a high car price (because of option load or bundling sales of derivatives products like financial service & window film etc.).",
      },
      {
        scenarios: ["stock_car", "stock_car_bev", "stock_car_phev"],
        objection:
          "The customer could think that the Dealer is offering a stock car because it is kind of 'aging stock'.",
      },
      {
        scenarios: ["stock_car", "stock_car_bev", "stock_car_phev"],
        objection:
          "Maybe the Dealer is not telling the customer that the new or face lift model is coming out soon and want to get rid of the stock cars.",
      },
      {
        scenarios: ["uv_conventional_engine"],
        objection:
          "You are not sure about the vehicle and question if you should be looking for a more different type of car.",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection:
          "The customer will compare with third party used car company and would think that Approved Cars are too expensive.",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection:
          "The customer is not sure about the vehicle conditions even this has been through the 111 points check list.",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection:
          "Price positioning may be high. Please select a UC which is still pretty new, quickly available yes but object to the price since you can not personalise the UC. Therefore ask yourself 'up loud' the question if you are not better of buying a new one for this little price difference.",
      },
      {
        scenarios: ["csv_e_performance_phev", "stock_car_phev", "uv_phev"],
        objection:
          "You are unsure whether to invest such a high amount of money for a vehicle, which in the electric mode is not emotional. I can not imagine that an hybrid car is as emotional as an conventional engine car.",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection: "I don't want to maintain the car in a Porsche Centre.",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection:
          "Macan: develop an objection related to the car's design: Is this the latest model design update, it looks maybe a bit dull compared to Cayenne.",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection:
          "911: develop an object which praises the car but also expresses concerns on ownership : this vehicle is just amazing, I'm just concerned about the long term value with the shift to electric sports cars like Taycan. Is it still the iconic 911",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection:
          "You are still unsure whether to buy Combustion or PHEV, what are the advantages/disadvantages of both.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "You think it's not easy to install the wall box at your home, and question the additional cost when moving (you are now considering moving house in the near future).",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "Charging time could be a barrier in comparison to combustion engine.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection: "Number of fast-charging infrastructure outside of Seoul.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "Charging points are still a major concern for electric drivers. Please mention that your Tesla friend has his own charging points as destination charging and also as supercharging points. Why does a brand like Porsche do not have this service? Or is it available also for Porsche?",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "Focus the objection on the topic range. You are used to driving 600km without refueling, without worries. You mention the blogs & other social media where clear critics are expressed on the announced (official) range and the true (realistic) range. This is a major concern since reliability is very important for you.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "What about holidays, I've heard about the traffic jams at charger stations, I am not really looking forward to that.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "I am not sure whether I want to hook the charging dock up to my home.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "I don't have enough space to hang a charging dock up to my home.",
      },
      {
        scenarios: [
          "csv_e_performance_bev",
          "csv_e_performance_phev",
          "stock_car_bev",
          "stock_car_phev",
          "uv_phev",
        ],
        objection:
          "Is the vehicle actually sustainable? I hear a lot of contradicting stories concerning the production of the battery and the livespan.",
      },
      {
        scenarios: [
          "csv_conventional_engine",
          "csv_e_performance_bev",
          "csv_e_performance_phev",
          "stock_car_bev",
          "stock_car_phev",
          "uv_phev",
          "uv_bev",
        ],
        objection:
          "I have heard a lot of dissatisfaction about Porsche Connect, so I don't know whether that will be really beneficial.",
      },
      {
        scenarios: [
          "csv_conventional_engine",
          "csv_e_performance_phev",
          "csv_e_performance_bev",
        ],
        objection: "The waiting/delivery time for the car is rather long",
      },
      {
        scenarios: [
          "csv_conventional_engine",
          "csv_e_performance_phev",
          "csv_e_performance_bev",
        ],
        objection:
          "You think that too many configurations are selected leading to a high car price (because of option load or bundling sales of derivatives products like financial service & window film etc.).",
      },
      {
        scenarios: ["all-scenarios"],
        objection: "Porsche could be too fancy for the me.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You might think that another brand might be more suitable than Porsche.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You have the impression that other brands are more sustainable and doubt the environmental friendliness of the vehicle.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You like a comfortable vehicle and therefore you are unsure how well the vehicle fits due to the sportive character.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You are unsure whether to invest such a high amount of money for a vehicle.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You want to challenge the cost of ownership since the value of new cars drop so significantly in the first months.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You are unsure how people will see you if you drive a Porsche. For example your business partners when having appointments with them.",
      },
      {
        scenarios: ["all-scenarios"],
        objection:
          "You need to talk with your tax consultant before making a decision.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "The Shopper states that the competitor has autopilot or similar systems. He worries that the Taycan couldn't provide similar function.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "The Shopper states that he has worries that the range is not enough. He is is traveling 1-2 days per month more than 450km per day. On a regular basis 40-60km per day.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "Competitors within the same price range in the market that offers up to 600 km range.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "I am interested in buying a Taycan but I''m very much concerned with charging infrastructure. I was told that in the end of the day.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "I am concerned about the home charging process. I leave in a building which does not have charging infrastructure and I'm afraid I wont be able to charge my car at home at all. Does Porsche offers any solution for that?",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection: "Customer is also checking Tesla models",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "I am not sure about the charging possibilities around my home",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "Charging in your garage is not possible and you question where to charge.",
      },
      {
        scenarios: ["csv_e_performance_bev", "stock_car_bev", "uv_bev"],
        objection:
          "The Shopper states that the DC charging cable is too heavy for lady to operate. Especially for rainy day.",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection:
          "You think that the delivery time is too long because you need a vehicle in 2 months.",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection:
          "You think that selecting too many configurations might delay the vehicle delivery because you need the vehicle soon",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection: "Depreciation of 911/718 with Taycan in the market",
      },
      {
        scenarios: ["csv_conventional_engine"],
        objection:
          "I am interested in a vehicle to drive together with my family and therefore safety is pretty important to me. Porsche Macan seems to be the most sportive vehicle in the category.",
      },
      {
        scenarios: ["csv_e_performance_phev", "stock_car_phev", "uv_phev"],
        objection:
          "Depreciation of the vehicle in the future with the market changing and new electric competitors.",
      },
      {
        scenarios: ["csv_e_performance_phev", "stock_car_phev", "uv_phev"],
        objection:
          "I am not sure about the electric range of the vehicle. I was told that it only runs for short distances (few kilometers) and in slow speeds. I don't want to buy a Porsche to drive slow or to have to worry about if I can get to my destiny or not. ",
      },
      {
        scenarios: ["csv_e_performance_phev", "stock_car_phev", "uv_phev"],
        objection: "Customer is also checking Mercedes GLE 350 Hybrid",
      },
      {
        scenarios: ["csv_e_performance_phev", "stock_car_phev", "uv_phev"],
        objection:
          "Will the increased petrol consumption on the highway erase all the savings I did while driving everyday ? (e.g. commuting from home to work around 30 km everyday - leaving for vacation twice a year 700 km)",
      },
      {
        scenarios: ["stock_car", "stock_car_bev", "stock_car_phev"],
        objection:
          "You might think that it is a bad idea to choose a stock car in regards to an upcoming new model-year change. (test the ability to push stock cars instead of giving in for a new order).",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection:
          "You are not sure about the condition of the vehicle and question if you can really trust the current good condition of the car.",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection: "You doubt that the previous owner treated the car well.",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection:
          "Further depreciation since the vehicle has depreciated a lot since it was new.",
      },
      {
        scenarios: ["uv_conventional_engine"],
        objection: "Customer is also checking Mercedes GLE 350 Hybrid",
      },
      {
        scenarios: ["uv_conventional_engine", "uv_bev", "uv_phev"],
        objection: "E.G. You are unsure about the prior history of the vehicle, and whether it was well maintained",
      },
    ],
  };
}
