import * as signalR from "@microsoft/signalr";
import appService from "./appService";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

class ConfgurationLockService {
  constructor() {
    const options = {
      accessTokenFactory: () => {
        return `${appService.getAccessToken()}`;
      },
      transport: signalR.HttpTransportType.LongPolling,
    };

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("/api/hubs/configurationlock", options)
      .configureLogging(signalR.LogLevel.Error)
      .build();

    this.connection.onclose(async () => {
      await this.start();
    });

    this.lockedConfigurations = [];

    this.connection.on("ReceiveLockedConfigurationsUpdate", (user, message) => {
      this.lockedConfigurations = message.split(" ").map((s) => Number(s));
      if (this.onSiteTable) {
        this.onSiteTable.$forceUpdate();
      }
      if (this.offSiteTable) {
        this.offSiteTable.$forceUpdate();
      }
    });
  }

  checkLocked(configId) {
    return this.lockedConfigurations.some((v) => v === configId);
  }

  async start() {
    try {
      while (!appService.auth) {
        await delay(500);
      }
      await this.connection.start();
    } catch (err) {
      await delay(3000);
      await this.start();
    }
  }

  async lockConfiguration(configId) {
    try {
      await this.connection.invoke("LockConfiguration", configId);
    } catch (err) {
      console.error(err);
    }
  }

  async unlockConfiguration() {
    try {
      await this.connection.invoke("UnlockConfiguration");
    } catch (err) {
      console.error(err);
    }
  }

  connectOnSiteTable(onSiteTable) {
    this.onSiteTable = onSiteTable;
  }

  connectOffSiteTable(offSiteTable) {
    this.offSiteTable = offSiteTable;
  }
}

export default new ConfgurationLockService();
