import { createStore } from "vuex";
import questions from "./questions/index";
import configurations from "./configurations/index";
import onSiteSections from "./on-site-sections/index";
import waves from "./waves/index";
import dealers from "./dealers/index";
import offSiteSections from "./off-site-sections/index";
import stories from "./stories/index";
import users from "./users/index";
import markets from "./markets/index";
import projects from "./projects";
import translations from "./translations";
import downloads from "./downloads";
import axios from "axios";

export default createStore({
  modules: {
    configurations,
    questions,
    onSiteSections,
    offSiteSections,
    waves,
    dealers,
    stories,
    users,
    markets,
    projects,
    translations,
    downloads,
  },
  state: {
    languages: [],
    logs: [],
    spinner: false,
    spinnercounter: 0,
    version: "",
  },
  actions: {
    getLanguages({ commit }, waveGuid) {
      axios
        .get(`/${process.env.VUE_APP_API_URI}/waves/${waveGuid}/languages`, {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        })
        .then((response) => {
          commit("setLanguages", response.data.data);
        });
    },
    async loadLogs(context, model) {
      try {
        const response = await axios.post(
          `/${process.env.VUE_APP_API_URI}/logs`,
          model,
          {
            headers: {
              CallSpinner: true,
            },
          }
        );
        if (response.data.success) {
          context.commit("setLogs", response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadVersion(context) {
      try {
        const response = await axios.get(
          `/${process.env.VUE_APP_API_URI}/version`,
          {
            headers: {
              CallSpinner: false,
            },
          }
        );
        context.commit("setVersion", response.data);
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    },
    getVersion(state) {
      return state.version;
    },
    showSpinner(state) {
      return state.spinnercounter > 0;
    },
    getLogs(state) {
      return state.logs;
    },
  },
  mutations: {
    setLogs(state, logs) {
      state.logs = logs;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setVersion(state, version) {
      state.version = version;
    },
    /** @deprecated **/
    setSpinnerState(state, spinner) {
      state.spinner = spinner;
    },
    addSpinner(state) {
      state.spinnercounter += 1;
    },
    lowerSpinner(state) {
      if (state.spinnercounter > 0) {
        state.spinnercounter -= 1;
      } else {
        state.spinnercounter = 0;
      }
    },
    resetSpinner(state) {
      state.spinnercounter = 0;
    },
  },
});
