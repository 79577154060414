import state from "./state";
import axios from "axios";
import { isGloballyWhitelisted } from "@vue/shared";

export default {
  namespaced: true,
  state: state,
  actions: {
    async loadProjects({ commit }) {
      await axios
        .get(`/${process.env.VUE_APP_API_URI}/projects`, {
          crossdomain: true,
          headers: {
            CallSpinner: true,
          },
        })
        .then((response) => {
          commit("setProjects", response.data.data);
        });
    },
    async setActiveProject({ dispatch, getters, commit }, projectGuid) {
      try {
        if (getters["getProjectsLoaded"] == false) {
          await dispatch("loadProjects");
        }
        if (getters["getActiveProjectGuid"] === projectGuid) {
          return;
        }
        commit("setActiveProject", projectGuid);
        if (getters["getActiveProjectGuid"] === null) {
          return;
        }
        await dispatch("waves/loadWaves", null, { root: true });
        commit("waves/resetActiveWave", null, { root: true });
      } catch (err) {
        console.error(err);
      }
    },
  },
  getters: {
    getProjects(state) {
      return state.projects;
    },
    getActiveProject(state) {
      return state.activeProject;
    },
    getActiveProjectGuid(state) {
      return state.activeProject ? state.activeProject.guid : null;
    },
    getProjectsLoaded(state) {
      return state.projectsLoaded;
    },
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects;
      state.projectsLoaded = true;
    },
    setActiveProject(state, projectGuid) {
      let project = state.projects.find((v) => v.guid === projectGuid);
      state.activeProject = project;
    },
  },
};
